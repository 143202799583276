<template>
  <div class="w100 bg-fff wtm_height pl-24 pr-24 pt-30 box autobox">
    <seanhead></seanhead>
    <div class="signbox w100 dp-f fw-w">
      <signItems
        :datas="state.Notice"
        :height="340"
        @reviewEmit="getDetails"
      ></signItems>
    </div>
    <el-pagination
      class="dp-f jc-c mb-20"
      @current-change="handleCurrentChange"
      background
      layout="prev, pager, next,slot"
      :current-page="currentPage"
      :total="totalPage"
    >
    </el-pagination>
  </div>
  <sealReview ref="sealReviewRef" @agree="agree"></sealReview>
</template>
<script setup>
import { onMounted, reactive, ref, unref, defineEmits } from "vue";
import seanhead from "./seanhead.vue";
import signItems from "./signItems.vue";
import sealReview from "./sealReview.vue"; //印章审核弹出框
import { getsignStatus } from "@/utils/server/getcode.js";
import qs from "qs";
import { httpToken } from "@/utils/request";
import { router_push_name } from "@/utils/server/router.js";
const emit = defineEmits(["Emit","updateNum"]);
const sealReviewRef = ref(); //印章审核弹出框ref
const loading = ref(false); //loading 显示
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const state = reactive({
  // status 1 通过 2停用 3 待审核 4未通过
  Notice: [],
});
const getDetails = (e) => {
  unref(sealReviewRef).getDetails(e);
};
// 分页改变方法
const handleCurrentChange = (e) => {
  currentPage.value = e;
  getdatalb(e);
};
//获取列表
let getdatalb = (val, searchjson = []) => {
  loading.value = true;

  let json = [
    // { column: "sort", type: 'orderByAsc' },
    // { column: "type_name", type: 'eq',value:state.sidebaritem.typeName }
  ];
  for (let i = 0; i < searchjson.length; i++) {
    json.push(searchjson[i]);
  }
  currentPage.value = val;
  state.datasearchjson = searchjson;
  httpToken({
    method: "post",
    url: "/seal/seal-admin/listByMyReview",
    data: qs.stringify({
      page: val,
      paramData: JSON.stringify(json),
    }),
  })
    .then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if (val !== res.data.current) {
        getdatalb(res.data.current, searchjson);
        return;
      }
      totalPage.value = res.data.total;
      // totalofpages.value = res.data.pages;
      state.Notice = res.data.records;
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    });
};

const agree = () => {

  getdatalb(currentPage.value);
  emit('updateNum')
};
onMounted(() => {
  getdatalb(1);
});
</script>
<style lang="scss" scoped >
.box {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.signbox {
  min-height: 600px;
}
</style>